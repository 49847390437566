import React from 'react';
import {
  bool, func, oneOf, string,
} from 'prop-types';

import {
  Flex, Button, Typography, Spinner,
} from '@components';
import styled from 'styled-components';

const ButtonWithLoader = ({
  text, onClick, isLoading, width, height,
  bgColor, disabled, minHeight, marginTop, className, type, hoverEffectColor,
  activeEffectColor, disabledEffectColor, color,
}) => (
  <Button
    onClick={onClick}
    width={width}
    height={height}
    marginTop={marginTop}
    minHeight={minHeight}
    bgColor={bgColor}
    disabled={disabled || isLoading}
    className={className}
    type={type}
    color={color}
    hoverEffect={hoverEffectColor}
    activeEffect={activeEffectColor}
    disabledEffect={disabledEffectColor}
  >
    <Flex itemsCenter justifyCenter position="relative">
      <Typography size="lg">{text}</Typography>
      <PositionedSpinner isLoading={isLoading} />
    </Flex>
  </Button>
);

const PositionedSpinner = styled(Spinner)`
    position: absolute;
    right: 20px;
`;

ButtonWithLoader.propTypes = {
  text: string.isRequired,
  onClick: func,
  isLoading: bool,
  width: string,
  height: string,
  bgColor: string,
  color: string,
  disabled: bool,
  minHeight: string,
  marginTop: string,
  className: string,
  type: oneOf(['primary', 'secondary', 'text']),
  hoverEffectColor: string,
  activeEffectColor: string,
  disabledEffectColor: string,
};

ButtonWithLoader.defaultProps = {
  onClick: () => {},
  isLoading: false,
  width: '100%',
  height: '48px',
  bgColor: 'green',
  color: undefined,
  disabled: false,
  minHeight: undefined,
  marginTop: undefined,
  className: undefined,
  type: undefined,
  hoverEffectColor: undefined,
  activeEffectColor: undefined,
  disabledEffectColor: undefined,
};

export default ButtonWithLoader;
