import React, { useEffect } from 'react';
import { ButtonWithLoader, Flex, Typography } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import {
  createStripeCheckoutSession,
  resetUserPaymentFlowState, setIsPaymentProcessing,
} from '@store/products/duck';
import {
  bool, func, number, shape, string,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '@hooks';
import { STORE_NAMES } from '@constants';
import { showErrorToast } from '@utils/helpers';
import { STRIPE_PRODUCT_TYPES } from '@constants/common';
import { setIsCheckoutSessionActivated } from '@utils/storage';

const PayWithKlarnaTab = ({
  subscription,
  disabled,
  trackInitiateCheckout,
  onSubmit,
  isFormDisabled,
}) => {
  if (disabled) {
    return null;
  }

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [isMobile, isDesktop] = useWindowSize();

  const {
    userPaymentFlow: {
      discount, promocode, promotionName, isPaymentProcessing,
    },
  } = useSelector(store => store[STORE_NAMES.PRODUCTS]);

  useEffect(() => {
    dispatch(resetUserPaymentFlowState());
  }, []);

  const finishSubmit = async () => {
    dispatch(setIsPaymentProcessing(false));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    dispatch(setIsPaymentProcessing(true));

    trackInitiateCheckout({
      sub: subscription,
      promotionProps: {
        discount,
        coupon: promocode,
        promotionName,
      },
    });

    const { customer } = await onSubmit();

    if (!customer) {
      finishSubmit();
      return;
    }

    const result = await dispatch(createStripeCheckoutSession({
      type: STRIPE_PRODUCT_TYPES.SUBSCRIPTION,
      productId: subscription.id,
      customerId: customer.id,
    }));

    if (result.error) {
      dispatch(setIsPaymentProcessing(false));
      showErrorToast(result.payload.data.error.message);
      return;
    }

    setIsCheckoutSessionActivated('true');

    const url = result.payload?.sessionUrl;
    window.location.href = url;

    finishSubmit();
  };

  return (
    <Flex
      column
      itemsStart
      width={isDesktop ? '50%' : '100%'}
      rowGap={isDesktop ? '32px' : '20px'}
    >
      <Typography color="grey" padding="sm" paddingBottom="0">
        {t('GetSubscription.Klarna.Description')}
      </Typography>
      <ButtonWithLoader
        width="100%"
        onClick={handleSubmit}
        text={t('GetSubscription.CheckoutButton')}
        isLoading={isPaymentProcessing}
        disabled={isFormDisabled}
      />
    </Flex>
  );
};

PayWithKlarnaTab.propTypes = {
  subscription: shape({
    id: number.isRequired,
    price: number.isRequired,
    price_after_first_payment: number.isRequired,
    duration: number.isRequired,
    title: string.isRequired,
    title_no: string.isRequired,
    has_chat: bool.isRequired,
    has_diet_plan: bool.isRequired,
    has_training_program: bool.isRequired,
    has_own_training_program: bool.isRequired,
    has_coach: bool.isRequired,
    has_coaching: bool.isRequired,
    has_booklets: bool.isRequired,
    has_recipes_db: bool.isRequired,
  }),
  disabled: bool.isRequired,
  trackInitiateCheckout: func.isRequired,
  onSubmit: func.isRequired,
  isFormDisabled: bool.isRequired,
};

PayWithKlarnaTab.defaultProps = {
  subscription: {},
};

export default PayWithKlarnaTab;
