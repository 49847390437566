export const getContainerStyles = ({ isDesktop, isOpen }) => ({
  width: isDesktop ? '50%' : '100%',
  style: {
    display: !isOpen ? 'none' : 'flex',
  },
});

export const styles = {
  checkoutButton: {
    width: '100%',
    minHeight: '48px',
    height: '48px',
    marginTop: 'auto',
    bgColor: 'green',
    flexShrink: 0,
  },
  subscriptionInfoAndCheckoutContainer: {
    paddingBottom: '50px',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '32px',
  },
  wrapper: {
    column: true,
    width: '100%',
    height: '100%',
  },
};

export const getContentContainerStyles = ({ isDesktop, isMobile }) => ({
  paddingLeft: isMobile ? 'xl' : 'xl10',
  paddingRight: isMobile ? 'xl' : 'xl10',
  justifyCenter: true,
  width: '100%',
  height: '100%',
  column: true,
  marginTop: isDesktop ? '80px' : '40px',
});

export const getGoBackButtonStyles = ({ isDesktop }) => ({
  marginBottom: isDesktop ? '30px' : '10px',
  marginLeft: isDesktop ? '22px' : '0px',
});
