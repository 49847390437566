import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  bool, number, shape, string,
} from 'prop-types';

import {
  Flex, Typography, SubscriptionFeatures, PaymentsInfo, Divider,
} from '@components';

import { useWindowSize } from '@hooks';
import { formatSubscriptionTitle } from 'src/utils/helpers';
import styled from 'styled-components';
import { theme } from '@styles';
import { getAvailableFeatures } from './SubscriptionBlock.layoutProps';

const SubscriptionBlock = ({ subscription }) => {
  const { t, i18n } = useTranslation();
  const [, isDesktop] = useWindowSize();

  const features = getAvailableFeatures(subscription);

  return (
    <Flex
      column
      marginBottom="xl"
      borderRadius="sm"
      padding={isDesktop ? 'xl4' : '0'}
      style={{
        maxWidth: '800px',
      }}
    >
      <Typography
        size="md"
        color="greyApple"
      >
        {`${subscription?.duration} ${t(`Subscriptions.${subscription?.duration > 1 ? 'Months' : 'Month'}`)}`}
      </Typography>
      <Title>
        {formatSubscriptionTitle(subscription, i18n.language)}
      </Title>
      <PaymentsInfo firstPayment={subscription.price} nextPayment={subscription.price_after_first_payment} />
      <Divider />
      <SubscriptionFeatures features={features} />
    </Flex>
  );
};

SubscriptionBlock.propTypes = {
  subscription: shape({
    id: number.isRequired,
    price: number.isRequired,
    price_after_first_payment: number.isRequired,
    duration: number.isRequired,
    title: string.isRequired,
    title_no: string.isRequired,
    has_chat: bool.isRequired,
    has_diet_plan: bool.isRequired,
    has_training_program: bool.isRequired,
    has_own_training_program: bool.isRequired,
    has_coach: bool.isRequired,
    has_coaching: bool.isRequired,
    has_booklets: bool.isRequired,
    has_recipes_db: bool.isRequired,
  }),
};

SubscriptionBlock.defaultProps = {
  subscription: {},
};

const Title = styled.h1`
  color: ${theme.color.darkSecondary};
  font-weight: 400;
  font-size: 18px;
  font-family: Poppins,sans-serif;
  line-height: normal;
`;

export default SubscriptionBlock;

