import {
  Button, Flex, Icon, Input, Typography,
} from '@components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, oneOf, string } from 'prop-types';
import { ReactComponent as RefreshIcon } from '@assets/Refresh.svg';
import { getCustomerByEmail } from '@store';
import { useDispatch } from 'react-redux';
import { showErrorToast } from 'src/utils/helpers';
import { EMAIL_FORM_VARIANTS, layoutByVariant } from './EmailForm.layout';

const EmailForm = ({
  value, onChange, variant, onVariantChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleEmailSubmit = async () => {
    const result = await dispatch(getCustomerByEmail({ email: value }));

    if (result.payload.data?.error) {
      showErrorToast(t('EmailForm.Toast.InvalidEmail'));
      return;
    }

    onVariantChange(result.payload?.customer
      ? EMAIL_FORM_VARIANTS.REGISTERED : EMAIL_FORM_VARIANTS.NOT_REGISTERED);
  };

  const handleEmailInputEnable = () => {
    onVariantChange(EMAIL_FORM_VARIANTS.DEFAULT);
  };

  const layout = layoutByVariant[variant];

  const buttonByVariant = (() => {
    switch (variant) {
      case EMAIL_FORM_VARIANTS.DEFAULT:
        return (
          <Button
            onClick={handleEmailSubmit}
            bgColor="green"
            color="white"
            height="48px"
            minWidth="129px"
          >
            {t('EmailForm.Continue')}
          </Button>
        );
      case EMAIL_FORM_VARIANTS.NOT_REGISTERED:
      case EMAIL_FORM_VARIANTS.REGISTERED:
        return (
          <Button
            onClick={handleEmailInputEnable}
            bgColor="white"
            minWidth="48px"
            paddingTop="5px"
          >
            <Icon SVG={RefreshIcon} />
          </Button>
        );
      default:
        return null;
    }
  })();

  return (
    <Flex column gap="8px" grow="0">
      <Typography size="xl4" weight="bold">
        {t(layout.title)}
      </Typography>
      <Typography weight="regular">
        {t(layout.description)}
      </Typography>
      <Flex grow="0" gap="16px">
        <Input
          placeholder="Your email"
          value={value}
          size="xl"
          onChange={onChange}
          disabled={variant !== EMAIL_FORM_VARIANTS.DEFAULT}
        />
        {buttonByVariant}
      </Flex>
      <Typography weight="regular" size="md" color="darkSecondary" opacity="0.6">
        {t(layout.helperText)}
      </Typography>
    </Flex>
  );
};

EmailForm.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  variant: oneOf(Object.values(EMAIL_FORM_VARIANTS)).isRequired,
  onVariantChange: func.isRequired,
};

export default EmailForm;
