import { useHistory } from 'react-router-dom';
import { Flex, Icon, Typography } from '@components';
import React from 'react';
import { func, shape, string } from 'prop-types';
import { ReactComponent as ArrowBack } from '@assets/ArrowBack.svg';
import { useWindowSize } from '../../hooks/useWindowSize';

const GoBackButton = ({ title, styles, onRedirect }) => {
  const history = useHistory();
  const { isMobile } = useWindowSize();

  const handleGoBack = () => {
    if (onRedirect) {
      onRedirect();
      return;
    }

    history.goBack();
  };

  return (
    <Flex itemsCenter {...styles}>
      <Flex grow="0" cursor="pointer" onClick={handleGoBack}>
        <Icon SVG={ArrowBack} height={isMobile ? 20 : 24} width={isMobile ? 20 : 24} />
      </Flex>
      {title && (
        <Typography
          size={isMobile ? 'xl' : 'xl2'}
          color="darkSecondary"
          marginLeft="md"
        >
          {title}
        </Typography>
      )}
    </Flex>
  );
};

GoBackButton.propTypes = {
  title: string,
  styles: shape({}),
  onRedirect: func,
};

GoBackButton.defaultProps = {
  title: '',
  styles: {},
  onRedirect: null,
};

export default GoBackButton;
