import {
  ButtonWithLoader, Flex, Input, PromocodeAppliedLabel, Typography,
} from '@components';
import { STORE_NAMES } from '@constants';
import {
  bool, func, number, string,
} from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkPromocode,
  setDiscount,
  setFreeDays,
  setPromocode,
  setPromotionName,
} from 'src/store/products/duck';
import {
  calculateDiscount,
  showErrorToast,
} from 'src/utils/helpers';
import { useWindowSize } from '@hooks/useWindowSize';
import theme from '@styles/theme';
import { Button } from './styles';
import { createCustomer, getCustomerByEmail } from '../../../../../store/customer/duck';

const BuySubscriptionBlock = ({
  handleSubmit,
  subscriptionId,
  subscriptionPrice,
  isStripeLoaded,
  isProduct,
  isFormDisabled,
  emailValue,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    userPaymentFlow: {
      discount,
      freeDays,
      promocode,
      isPaymentProcessing,
      isBuyButtonDisabled,
    },
    isPending,
  } = useSelector(store => store[STORE_NAMES.PRODUCTS]);
  const { customer } = useSelector(store => store[STORE_NAMES.CUSTOMER]);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPromocodeApplied, setIsPromocodeApplied] = useState(false);

  const { isMobile } = useWindowSize();

  const handlePromocodeInputChange = e => {
    const value = e.target.value.toUpperCase();

    if (isPromocodeApplied && value !== promocode) {
      setIsPromocodeApplied(false);
    }

    setError(false);
    setErrorMessage('');
    dispatch(setPromocode(value));
  };

  const applyPromocode = async event => {
    event.preventDefault();

    if (!promocode) {
      setError(true);
      return;
    }

    let customerId = customer?.id;

    if (!customer) {
      const result = await dispatch(createCustomer({ email: emailValue }));
      customerId = result?.payload?.customer?.id;

      dispatch(getCustomerByEmail({ email: emailValue }));
    }

    const res = await dispatch(checkPromocode({
      code: promocode,
      subscriptionId,
      isProduct,
      customerId,
    }));

    if (res.error) {
      showErrorToast(t('Toast.Error'));
    } else {
      const promocodeObject = res.payload.promocode;

      if (!promocodeObject.isValid) {
        setError(true);
        setErrorMessage(t('GetSubscription.Promocode.Invalid'));
        return;
      }

      if (!promocodeObject.data) {
        setError(true);
        setErrorMessage(t('GetSubscription.Promocode.IsNotApplicable'));
        return;
      }

      const promocodeDiscount = calculateDiscount({
        price: subscriptionPrice,
        amountOff: promocodeObject.data.amount_off,
        percentOff: promocodeObject.data.percent_off,
      });

      setIsPromocodeApplied(true);
      dispatch(setPromotionName(promocodeObject.data.promotion_name));
      dispatch(setDiscount(promocodeDiscount));
      dispatch(setFreeDays(promocodeObject.data.free_days));
    }
  };

  const isPromocodeLabelVisible = isPromocodeApplied && !error;
  const isPromocodeSubmitDisabled = isFormDisabled || !promocode;

  return (
    <>
      <Flex justifyBetween columnGap="16px" rowGap={isMobile ? '8px' : '20px'} grow="0" column={isMobile}>
        <Flex column columnGap="16px" rowGap="8px">
          <Input
            size="xl"
            placeholder={t('GetSubscription.Promocode.InputPlaceholder')}
            value={promocode}
            onChange={handlePromocodeInputChange}
            isError={error}
            disabled={isFormDisabled}
          />
          <Typography size="md" color="greyApple" marginTop="6px" marginLeft="10px">
            {errorMessage}
          </Typography>
        </Flex>
        {
          isPromocodeApplied ? (
            <Button
              type="secondary"
              onClick={applyPromocode}
              height="48px"
              width={isMobile ? '100%' : '50%'}
              text={t('GetSubscription.Promocode.Applied')}
            />
          ) : (
            <ButtonWithLoader
              hoverEffectColor={theme.color.platinum}
              activeEffectColor={theme.color.platinum}
              disabledEffectColor={theme.color.platinum}
              bgColor="platinum"
              color={isPromocodeSubmitDisabled ? 'white' : 'darkSecondary'}
              onClick={applyPromocode}
              width={isMobile ? '100%' : '50%'}
              text={t('GetSubscription.Promocode.Apply')}
              disabled={isPaymentProcessing || isPromocodeSubmitDisabled}
              isLoading={isPending && !isPaymentProcessing}
            />
          )
        }
      </Flex>
      <Flex column={!isPromocodeLabelVisible || isMobile} height="100%">
        <PromocodeAppliedLabel
          isApplied={isPromocodeLabelVisible}
          discount={discount}
          freeDaysAmount={freeDays}
          newSubscriptionPrice={subscriptionPrice - discount}
        />
        <Button
          minHeight="48px"
          isMobile={isMobile}
          width={isMobile || !isPromocodeLabelVisible ? '100%' : '50%'}
          marginTop={isMobile ? 'auto' : '0'}
          onClick={handleSubmit}
          text={t('GetSubscription.PayButton')}
          isLoading={isPaymentProcessing}
          disabled={!isStripeLoaded || isBuyButtonDisabled || isFormDisabled}
        />
      </Flex>
    </>
  );
};

BuySubscriptionBlock.propTypes = {
  handleSubmit: func.isRequired,
  subscriptionId: number,
  subscriptionPrice: number,
  isStripeLoaded: bool.isRequired,
  isProduct: bool.isRequired,
  isFormDisabled: bool.isRequired,
  emailValue: string.isRequired,
};

BuySubscriptionBlock.defaultProps = {
  subscriptionId: null,
  subscriptionPrice: null,
};

export default BuySubscriptionBlock;

