import { theme } from '@styles';

export const CARD_ELEMENT_OPTIONS = {
  showIcon: true,
  hidePostalCode: true,
  disableLink: true,
  style: {
    base: {
      fontFamily: theme.typography.family,
      fontSize: theme.typography.size.xl,
      fontWeight: theme.typography.weight.regular,
      padding: '50',
      height: '100',

      '::placeholder': {
        color: theme.color.grey,
      },
    },
  },
};
