export const PAGE_STATE = {
  SUBSCRIPTION_INFO: 'SUBSCRIPTION_INFO',
  CHECKOUT: 'CHECKOUT',
  ALL: 'ALL',
};

export const getGoBackTitle = ({ isDesktop, t }) => {
  if (isDesktop) return t('GetSubscription.Title');

  return t('GetSubscription.GoBack');
};
