import {
  LogInScreen,
  SignUpScreen,
  RootScreen,
  VerifyEmailScreen,
  ResetPasswordScreen,
  ChangePasswordScreen,
  Subscriptions,
  Subscription,
  UserPurchases,
  NotFoundScreen,
  NonConsumableProduct,
  ConsumableProduct,
  SuccessfulPurchaseScreen,
  EmailVerification,
} from '@screens';
import { routes } from '@utils';
import React from 'react';
import {
  BrowserRouter, Redirect, Switch,
} from 'react-router-dom';
import AuthorizedRoute from '../AuthorizedRoute';
import UnauthorizedRoute from '../UnauthorizedRoute';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <UnauthorizedRoute path={routes.subscriptions} exact>
        <Subscriptions />
      </UnauthorizedRoute>
      <UnauthorizedRoute exact path={routes.root}>
        <RootScreen />
      </UnauthorizedRoute>
      <UnauthorizedRoute path={routes.logIn}>
        <LogInScreen />
      </UnauthorizedRoute>
      <UnauthorizedRoute path={routes.signUp}>
        <SignUpScreen />
      </UnauthorizedRoute>
      <UnauthorizedRoute path={routes.verifyEmail}>
        <VerifyEmailScreen />
      </UnauthorizedRoute>
      <UnauthorizedRoute path={routes.resetPassword}>
        <ResetPasswordScreen />
      </UnauthorizedRoute>
      <UnauthorizedRoute path={routes.changePassword}>
        <ChangePasswordScreen />
      </UnauthorizedRoute>
      <UnauthorizedRoute path={`${routes.subscriptions}/:id`}>
        <Subscription />
      </UnauthorizedRoute>
      <AuthorizedRoute path={`${routes.products}/non-consumable/:id`}>
        <NonConsumableProduct />
      </AuthorizedRoute>
      <AuthorizedRoute path={`${routes.products}/consumable/:id`}>
        <ConsumableProduct />
      </AuthorizedRoute>
      <AuthorizedRoute path={routes.purchases}>
        <UserPurchases />
      </AuthorizedRoute>
      <UnauthorizedRoute path={routes.success}>
        <SuccessfulPurchaseScreen />
      </UnauthorizedRoute>
      <UnauthorizedRoute path={routes.notFound}>
        <NotFoundScreen />
      </UnauthorizedRoute>
      <UnauthorizedRoute path={routes.changeEmailVerification}>
        <EmailVerification />
      </UnauthorizedRoute>
      <Redirect to={routes.notFound} />
    </Switch>
  </BrowserRouter>
);

export default Router;
