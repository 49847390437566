import styled from 'styled-components';
import { ButtonWithLoader } from '@components';

export const Button = styled(ButtonWithLoader)`
     ${({ isMobile }) => isMobile && `
        position: fixed;
        bottom: 80px;
        width: calc(100% - 32px);
     `}
`;
