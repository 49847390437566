import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  Flex, Header, HeaderMobile,
} from '@components';
import { storage } from '@utils';
import { useQuery, useWindowSize } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { STORE_NAMES } from '@constants';
import { clearGtmDataLayer } from 'src/utils/helpers';
import { getPurchaseGTMTrackingData } from 'src/store/analytics/duck';
import { getUtmParameters } from 'src/utils/storage';
import { ReactComponent as RunningManIcon } from '@assets/RunningMan.svg';
import SuccessStatus from '../../components/SuccessStatus/SuccessStatus';

const SuccessfulPurchaseScreen = () => {
  const { t } = useTranslation();
  const [, isDesktop] = useWindowSize();
  const dispatch = useDispatch();
  const query = useQuery();
  const location = useLocation();

  const [isAuthorized] = useState(!!storage.getToken());

  const {
    availableProducts: {
      subscriptions,
    },
  } = useSelector(store => store[STORE_NAMES.PRODUCTS]);
  const { authorization_info } = useSelector(store => store[STORE_NAMES.AUTH]);
  const { customer } = useSelector(store => store[STORE_NAMES.CUSTOMER]);

  const [purchasedSubscription, setPurchasedSubscription] = useState({});
  const isPurchaseTracked = useRef(false);

  const trackGTMPurchase = async subscription => {
    const paymentIntentId = location.state?.paymentIntentId;
    const statePromocode = location.state?.promocode;
    const stateDiscount = location.state?.discount;
    const statePromotionName = location.state?.promotionName;

    const gtmTrackingData = await dispatch(getPurchaseGTMTrackingData({
      subscriptionId: subscription.id,
      isKlarna: Boolean(paymentIntentId),
      customerId: customer.id,
    }));

    if (!gtmTrackingData.error) {
      const {
        order_id,
        transaction_id,
        promotion_code,
        promotion_name,
        discount,
        amount_total,
        signup_date,
      } = gtmTrackingData.payload;
      const utmParams = JSON.parse(getUtmParameters() || '{}');

      clearGtmDataLayer();
      window.dataLayer.push({
        event: 'purchase',
        eventProps: {
          value: typeof stateDiscount === 'number'
            ? (subscription.price - stateDiscount) / 100
            : amount_total / 100,
          currency: 'NOK',
          items: [{
            item_id: subscription.id,
            item_name: subscription.title,
            discount: (discount ?? stateDiscount) / 100,
            item_category: 'subscription',
            promotion_id: subscription.id,
            promotion_name: promotion_name ?? statePromotionName,
          }],
          transaction_id: transaction_id || paymentIntentId,
          coupon: promotion_code || statePromocode,
          // Google Ads props
          conversion_label: subscription.title,
          order_id,
        },
        meta: {
          email: authorization_info.email,
          eventProps: {
            content_name: subscription.title,
            content_category: 'fitness',
            content_type: 'subscription',
            contents: [{
              id: subscription.id,
              quantity: 1,
            }],
            content_ids: [subscription.id],
            num_items: 1,
            value: subscription.price / 100,
            currency: 'NOK',
          },
        },
        userProps: {
          user_id: authorization_info.id,
          subscription_id: subscription.id,
          subscription_plan: subscription.title,
          subscription_price: subscription.price,
          coupon_code_used: promotion_code || statePromocode,
          payment_method: paymentIntentId ? 'card' : 'klarna',
          signup_date,
          ...utmParams,
        },
      });
    }
  };

  useEffect(() => {
    if (subscriptions.length !== 0 && !purchasedSubscription?.id) {
      const subscriptionId = query.get('subscriptionId');
      const foundSubscription = subscriptions.find(
        subscription => subscription.id === +subscriptionId,
      );
      setPurchasedSubscription(foundSubscription);
    }
  }, [subscriptions]);

  const trackCustomerPurchase = subscription => {
    trackGTMPurchase(subscription);
  };

  useEffect(() => {
    if (purchasedSubscription?.id && !isPurchaseTracked.current) {
      trackCustomerPurchase(purchasedSubscription);
    }
  }, [purchasedSubscription]);

  return (
    <Flex column minHeight="100vh">
      {isAuthorized && (
        isDesktop ? <Header /> : <HeaderMobile />
      )}
      <Flex
        grow="0"
        justifyCenter
        itemsCenter
        bgImage="linear-gradient(89.94deg, #1BC98E 0.05%, #4DA1F4 99.96%)"
        minHeight={isAuthorized ? 'calc(100vh - 72px)' : '100dvh'}
        padding="10px"
      >
        <SuccessStatus
          SVG={RunningManIcon}
          title={t('SuccessfulPurchaseScreen.Title')}
          subtitle={t('SuccessfulPurchaseScreen.Subtitle')}
          withSocialLinks
        />
      </Flex>
    </Flex>
  );
};

export default SuccessfulPurchaseScreen;
