/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { MOBILE_WIDTH, TABLET_WIDTH } from '@constants/common';

export const devices = {
  desktop: 1440,
  desktopLG: 1440,
  fullHD: 1920,
  laptop: 1024,
  mobile: 501,
  smallHeight: 830,
  tabletLG: 780,
  tabletSM: 530,
};

const useWindowSizeLegacy = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isDesktop = windowWidth > TABLET_WIDTH;
  const isMobile = windowWidth < MOBILE_WIDTH;

  return [isMobile, isDesktop];
};

// new hook with more properties
// left old one to not break existing code
export const useWindowSize = () => {
  const getInitialDimensions = () => ({
    device:
        window.innerWidth <= devices.mobile
          ? 'mobile'
          : window.innerWidth <= devices.tabletLG
            ? 'tablet'
            : window.innerWidth <= devices.laptop
              ? 'laptop'
              : 'desktop',
    dynamicHeight: window.innerHeight,
    dynamicWidth: window.innerWidth,
    isLaptop: window.innerWidth <= devices.laptop,
    isMobile: window.innerWidth <= devices.mobile,
    isTabletLG: window.innerWidth <= devices.tabletLG,
    isTabletSM: window.innerWidth <= devices.tabletSM,
    isDesktop: window.innerWidth >= devices.laptop,
  });

  const [windowSize, setWindowSize] = useState(getInitialDimensions());

  const setDimension = () => {
    setWindowSize(getInitialDimensions());
  };

  useEffect(() => {
    setDimension();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', setDimension);

      return () => {
        window.removeEventListener('resize', setDimension);
      };
    }
    return 0;
  }, [windowSize]);

  return windowSize;
};

export default useWindowSizeLegacy;
